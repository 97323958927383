export default [
  'General',
  [
    {
      to: '/',
      label: 'Home'
    }
    // {
    //   to: '/custom-page',
    //   label: 'Custom Page'
    // }
  ],
  'Routes',
  [
    {
      to: '/areas-of-interest',
      label: 'AreasOfInterest'
    },
    {
      to: '/blogs-category',
      label: 'Blogs Category'
    },
    {
      to: '/blogs',
      label: 'Blogs'
    },
    {
      to: '/carousel',
      label: 'Carousel'
    },
    {
      to: '/customer-stories',
      label: 'Customer Stories'
    },
    {
      to: '/custom-search',
      label: 'Custom Search'
    },
    {
      to: '/events',
      label: 'Events'
    },
    {
      to: '/faq',
      label: 'Faq'
    },
    {
      to: '/hover-blocks',
      label: 'Hover Blocks'
    },
    {
      to: '/language',
      label: 'Language'
    },
    {
      to: '/leaders',
      label: 'Leaders'
    },
    {
      to: '/legacy',
      label: 'Legacy'
    },
    {
      to: '/links',
      label: 'Links'
    },
    {
      to: '/list-content',
      label: 'List Content'
    },
    {
      to: '/news-category',
      label: 'News Category'
    },
    {
      to: '/meta',
      label: 'Meta'
    },
    {
      to: '/news',
      label: 'News'
    },
    {
      to: '/titles',
      label: 'Titles'
    },
    {
      to: '/parallax',
      label: 'Parallax'
    },
    {
      to: '/vacancies',
      label: 'Vacancies'
    },
    {
      to: '/zigzags',
      label: 'Zigzags'
    }],
  'Auth',
  [{
    to: '/roles',
    label: 'Roles'
  },
  {
    to: '/users',
    label: 'Users'
  },
  {
    to: '/survey-form',
    label: 'Survey Form'
  },
  {
    to: '/survey-form-template',
    label: 'Survey Form Template'
  }]
]
