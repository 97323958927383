import Vue from 'vue'
import Vuex from 'vuex'
import { HTTP } from '../axios/axios'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: [],
    currentPage: '',
    status: '',
    token: Cookies.get('authToken') || '',
    user: {},
    modalState: {
      type: '',
      currentItem: {},
      prevItem: {},
      changed: false
    }
  },
  mutations: {
    setData (state, data) {
      state.data = data
    },
    setType (state, type) {
      state.modalState.type = type
    },
    setCurrentItem (state, currentItem) {
      state.modalState.currentItem = currentItem
    },
    setPrevItem (state, prevItem) {
      state.modalState.prevItem = prevItem
    },
    setChanged (state, changed) {
      state.modalState.changed = changed
    },
    setCurrentPage (state, currentPage) {
      state.currentPage = currentPage
    },
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error (state) {
      state.status = 'error'
    },
    logout (state) {
      state.status = ''
      state.token = ''
    }
  },
  actions: {
    changeLanguageData ({ commit, state }, payload) {
      let temp = state.data
      const length = payload.path.length
      for (let i = 0; i < payload.path.length - 2; i++) {
        temp = temp[payload.path[i]]
      }
      temp[payload.path[length - 1]] = payload.val
      commit('setData', state.data)
    },
    login ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        HTTP.post('/auth/login', user)
          .then(res => {
            Cookies.set('authToken', res.data.accessToken, { secure: true, expires: 1 })
            HTTP.defaults.headers.common.Authorization = res.data.accessToken
            commit('auth_success', res.data.accessToken, res.data.user)
            resolve(res)
          })
          .catch(err => {
            commit('auth_error')
            Cookies.remove('authToken')
            reject(err)
          })
      })
    },
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        Cookies.remove('authToken')
        delete HTTP.defaults.headers.common.Authorization
        resolve()
      })
    }
  },
  getters: {
    hasToken: state => !!state.token,
    authStatus: state => state.status
  },
  modules: {
  }
})
