<template>
  <div class="home">
    <h1>Welcone to admin Panel</h1>
    <p class="mt-10 mb-2 font-bold">{{user.username }}</p>
    <h3>Roles:</h3>
    <p v-for="i in user.roles" :key="i">{{ '- ' + i }}</p>
  </div>
</template>

<script>
import { HTTP } from '@/axios/axios'
import Cookies from 'js-cookie'

export default {
  name: 'Home',
  data () {
    return {
      user: {}
    }
  },
  async beforeCreate () {
    const userData = await HTTP.get('/auth/' + Cookies.get('authToken'))
    this.user = userData.data
  }
}
</script>
