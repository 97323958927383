import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Cookies from 'js-cookie'
import { HTTP } from '../axios/axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/customer-stories',
    name: 'Customer Stories',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/CustomerStories.vue')
  },
  {
    path: '/leaders',
    name: 'Leaders',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Leaders.vue')
  },
  {
    path: '/areas-of-interest',
    name: 'AreasOfInterest',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/AreasOfInterest.vue')
  },
  {
    path: '/zigzags',
    name: 'Zigzags',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Zigzags.vue')
  },
  {
    path: '/addPage',
    name: 'addPage',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/AddZigzagPage.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/News.vue')
  },
  {
    path: '/news-category',
    name: 'NewsCategory',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/NewsCategory.vue')
  },
  {
    path: '/events',
    name: 'Events',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Events.vue')
  },
  {
    path: '/blogs',
    name: 'Blogs',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Blogs.vue')
  },
  {
    path: '/blogs-category',
    name: 'BlogsCategory',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/BlogsCategory.vue')
  },
  {
    path: '/parallax',
    name: 'Parallax',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Parallax.vue')
  },
  {
    path: '/titles',
    name: 'Titles',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Titles.vue')
  },
  {
    path: '/hover-blocks',
    name: 'HoverBlocks',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/HoverBlocks.vue')
  },
  {
    path: '/links',
    name: 'Links',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Links.vue')
  },
  {
    path: '/carousel',
    name: 'Carousel',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Carousel.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/list-content',
    name: 'ListContent',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/ListContent.vue')
  },
  {
    path: '/vacancies',
    name: 'Vacancies',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Vacancies.vue')
  },
  {
    path: '/legacy',
    name: 'Legacy',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Legacy.vue')
  },
  {
    path: '/custom-search',
    name: 'CustomSearch',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/CustomSearch.vue')
  },
  {
    path: '/language',
    name: 'Language',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Language.vue')
  },
  {
    path: '/meta',
    name: 'Meta',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Meta.vue')
  },
  {
    path: '/roles',
    name: 'Roles',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Roles.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Users.vue')
  },
  {
    path: '/survey-form',
    name: 'SurveyForm',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SurveyForm.vue')
  },
  {
    path: '/survey-form-template',
    name: 'SurveyTemplate',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SurveyTemplate.vue')
  }
  // {
  //   path: '/custom-page',
  //   name: 'CustomPage',
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () => import('../views/CustomPage.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const isLoggedIn = async () => {
  // return true
  const token = Cookies.get('authToken')
  let loggedin = false
  if (token) {
    try {
      await HTTP.get('/auth/' + token)
      loggedin = true
    } catch (error) {
      console.log(error)
    }
  }
  return loggedin
}

export const isAdminOrModerator = async () => {
  // return true
  let res = false
  if (!Cookies.get('authToken')) {
    return res
  }
  const userData = await HTTP.get('/auth/' + Cookies.get('authToken'))
  const rolesData = await HTTP.get('/roles')
  const userRoles = rolesData.data.filter(i => userData.data.roles.includes(i.name))
  userRoles.forEach(role => {
    role.permission.forEach(i => {
      if (i.name === 'admin' || i.name === 'moderator') {
        res = true
      }
    })
  })
  return res
}

router.beforeEach(async (to, from, next) => {
  const loggedin = await isLoggedIn()
  if (to.name === 'Home') {
    if (loggedin) {
      next()
      return
    }
    next('/login')
  } else if (to.name === 'Login') {
    if (loggedin) {
      next('/')
      return
    }
    next()
  } else {
    if (loggedin) {
      if (await isAdminOrModerator()) {
        next()
        return
      }
      next('/')
      return
    }
    next('/login')
  }
})

export default router
