<template>
  <div id="app">
    <modal name="errorModal" />
    <div class="flex flex-start">
      <aside-menu :menu="menu" v-if="$route.path !== '/login'" />

      <div class="w-full p-6" :class="{ 'ml-60': $route.path !== '/login' }">
        <router-view
          v-show="!isLoading"
          @loading="loading"
          @showErrorModal="showErrorModal"
        />

        <div v-if="isLoading" class="flex items-center justify-center h-screen80">
          <loader :loading="isLoading" :color="'#1f2937'" :size="'100px'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menu from '@/menu.js'
import AsideMenu from '@/components/AsideMenu.vue'
import ErrorModal from './components/ErrorModal.vue'

export default {
  name: 'Home',
  components: {
    AsideMenu
  },
  data () {
    return {
      menu,
      isLoading: false
    }
  },
  methods: {
    showErrorModal () {
      this.$modal.show(ErrorModal, {}, { width: 500, height: 300 })
    },
    loading (bool) {
      this.isLoading = bool
    }
  }
}
</script>

<style lang="scss">
</style>
