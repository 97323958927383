import axios from 'axios'
import Cookies from 'js-cookie'

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: 'Bearer ' + (Cookies.get('authToken') || '')
  }
})

HTTP.interceptors.request.use((config) => {
  const token = Cookies.get('authToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})
