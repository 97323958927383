<template>
  <aside class="fixed w-60 h-screen bg-gray-800">
    <div class="h-full flex flex-col justify-between align-center">
      <div
        class="flex flex-row items-center w-full bg-gray-900 text-white h-14"
      >
        <div class="flex-1 px-3 py-4">
          <logo class="logo mx-auto"/>
        </div>
      </div>

      <div
        v-if="admin"
        class="overflow-y-scroll scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-600 scrollbar-track-gray-900 "
      >
        <template v-for="(menuGroup, index) in menu">
          <p
            v-if="typeof menuGroup === 'string'"
            :key="`a-${index}`"
            class="p-3 text-xs uppercase text-gray-400"
          >
            {{ menuGroup }}
          </p>
          <ul v-else :key="`b-${index}`">
            <li
              v-for="(menuitem, index) in menuGroup"
              :key="index"
              @click="$store.commit('setCurrentPage', menuitem.label)"
            >
              <component
                class="flex cursor-pointer hover:bg-gray-600 dark:hover:bg-gray-700 py-2 pl-8 pr-4 text-gray-300 "
                :is="'router-link'"
                :to="menuitem.to || null"
              >
                <span>
                  {{ menuitem.label }}
                </span>
              </component>
            </li>
          </ul>
        </template>
      </div>

      <div class="flex flex-wrap w-full text-white bg-gray-900">
        <span @click="logout" class="cursor-pointer w-full py-2.5 pl-8 hover:bg-gray-600">
          Logout
          <font-awesome-icon icon="sign-out-alt" class="mirrorVertical mr-1" />
        </span>
      </div>
    </div>
  </aside>
</template>

<script>
import logo from '../assets/logo.svg'
import { isAdminOrModerator } from '@/router/index'

export default {
  name: 'AsideMenu',
  data () {
    return {
      admin: false
    }
  },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  components: {
    logo
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
    }
  },
  async beforeMount () {
    this.admin = await isAdminOrModerator()
  }
}
</script>

<style lang="scss">
.logo{
  > *{
      fill: white;
    }
}
</style>
