<template>
    <div class="w-full h-full text-center flex justify-center flex-wrap items-center">
        <div class="w-full flex flex-wrap justify-center">
            <font-awesome-icon icon="exclamation" class="text-5xl text-red-500" />
            <span class="w-full text-4xl font-bold my-3">Oops...</span>
            <span class="w-full text-xl font-bold">Something went wrong</span>
        </div>
        <span class="border border-black rounded-lg px-5 py-2 cursor-pointer" @click="close">Close</span>
    </div>
</template>

<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style>

</style>
